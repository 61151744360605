.post{
    display: flex;
    justify-content: space-between;
}
.middle-p{
    padding: 2rem;
    padding-top: 1.5rem;
    margin-left: 20rem;
    margin-right: 20rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.stroke-texttt{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  
font-size: xx-large;
}
.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    align-items: center;
    color: var(--lightgrayz);
}
.email-container>textarea{
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    align-items: center;
    color: var(--lightgrayz);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background-color: var(--orange);
    color: white;
}
.btn{
    margin-left: 4rem;
    margin-top: 2rem;
    
    background-color: var(--orange);
    color: white;
}
.btn>:link{
text-decoration: none;
color: aliceblue;
}
.btn>:visited{
text-decoration: none;
}
.btn>:hover{
text-decoration: none;
}
.btn>:active{
text-decoration: none;
}
@media screen and (max-width:768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }

    .right-j{
        padding: 2rem;
    }
}