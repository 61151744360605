.contact{
    display: flex;
    justify-content: space-between;
}

.contact-menu{
    list-style: none;
    display: flex;
    gap: 5rem;
    color:white;
}
.contact-menu>li:hover{
    cursor: pointer;
    color: var(--orange);    

}
.contact-menu>li:link{
    cursor: pointer;
    color: var(--orange);    
    counter-increment: 50%;

}